<script setup>
import { useTemplateStore } from "@/stores/template";
import { defineComponent } from 'vue'
import { VideoPlayer } from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
// import from public
import '@/assets/css/videojs.css'
// Main store
const store = useTemplateStore();


function onPlayerError() {
  console.log('Player error:')
}

</script>

<template>
  <div>
    <!-- Hero -->
    <div id="one-vue-hero" class="bg-page-landing pb-5">

      <div class="row justify-content-center m-0">
        <div class="col-12 col-md-8 ">
          <div class="row">
            <div class="col col-12 col-md-6">
              <h1 class="h1 mt-6 mt-md-8">
                Let's make your site convert.
              </h1>

              <p class="fs-5 fw-medium text-muted mb-4 ">
                Run an AI-Powered Audit to find out why your visitors are leaving and how to fix it.
              </p>


              <div class="row">
                <div class="col-sm-12 d-flex ">
                  <div class="d-grid d-md-flex ">
                    <router-link class="btn btn-primary" aria-current="page" :to="{name: 'auth-signup'}">Get Started
                    </router-link>

                  </div>
                  <div class="ms-2">
                    <a class="btn btn-light" aria-current="page" href="https://pagewatch.ai/daeeb804-75f5-46f6-b55e-0755cf51f95c/2ydb8goq/dashboard">View
                      Demo
                    </a>
                  </div>
                  <br>

                </div>
                <div class="col-sm-12 d-flex mt-2">
                  <div class="d-grid d-md-flex ">
                    <span class="text-muted"
                          style="font-size: 80%"> Check you home page for free.</span>
                  </div>
                  <br>

                </div>

              </div>

            </div>
            <div class="col-md-6 pt-4 pt-md-7 pb-3">
              <div style="max-width: 900px">
                <video-player
                    src="/assets/media/video/acme.mp4"
                    poster="/assets/media/video/acme.png"
                    controls
                    :loop="true"
                    :volume="0.0"
                    :fluid="true"
                    @error="onPlayerError"
                >

                </video-player>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END Hero -->
    <!-- END Hero After -->
    <div class="row justify-content-center m-0">
      <div class="col-sm-8 ">
        <div class="d-flex gap-3 flex-row text-center align-items-center pe-4">
          <div class="content mt-0 pt-0 social-proof-box">
            <div class="py-2 text-center">
              ”The PageWatch tool is incredible. It actually out-performed a manual audit that a hired large agency
              did for one of my most recent sites.”
              <div class="container text-center">
                <div class="row">
                  <div class="col">
                    <img style="width:50px;height:50px;border-radius: 50%"
                         src="https://ph-avatars.imgix.net/2723444/original?auto=format&auto=compress&codec=mozjpeg&cs=strip&w=170&h=170&fit=crop">
                  </div>
                  <div class="col col-6 text-start  mt-2">
                    <div class="social-proof-author">Paul Hoft</div>
                    <div class="social-proof-source">Web developer</div>
                  </div>
                  <div class="col text-end mt-2">
                    <a href="https://www.producthunt.com/products/pagewatch?comment=1088080#pagewatch"
                       target="_blank">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 40 40"
                           class="styles_logo__xwGFq">
                        <g fill="none" fill-rule="evenodd">
                          <path fill="#FF6154"
                                d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20"></path>
                          <path fill="#FFF"
                                d="M22.667 20H17v-6h5.667a3 3 0 0 1 0 6m0-10H13v20h4v-6h5.667a7 7 0 1 0 0-14"></path>
                        </g>
                      </svg>
                    </a>

                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="content mt-0 pt-0 social-proof-box d-none d-md-block">
            <div class="py-2 text-center">
              ”At Spike.sh, we benefited a ton by PageWatch giving us timely alerts about spelling mistakes, CSS and
              website issues. Highly recommended.”
              <div class="container text-center">
                <div class="row">
                  <div class="col">
                    <img style="width:50px;height:50px;border-radius: 50%"
                         src="https://pagewatch.dev/assets/img/testimonials/testimonials-3.jpg">
                  </div>
                  <div class="col col-6 text-start  mt-2">
                    <div class="social-proof-author">Kaushik Thirthappa</div>
                    <div class="social-proof-source">Spike.sh</div>
                  </div>
                  <div class="col text-end mt-2">
                    <a href="https://spike.sh/"
                       target="_blank">
                      <img src="https://ik.imagekit.io/pagewatch/vrqla7nc/favicon.png?tr=w-20">
                    </a>

                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="container-fluid mt-6">
      <div class="row justify-content-center section-color pb-4">
        <div class="col-md-8">
          <div class="row pt-6">
            <div class="col col-12 feature-preview d-block d-lg-none">
              <center>
              <div style="max-width: 240px" class="ps-2">
                <img class="img-fluid" src="/assets/media/video/audit3.png">
              </div>
              </center>
            </div>
            <div class="col-12 col-lg-7">
              <h3 class="card-title mt-5 mb-3">Prevent Embarrassing Mistakes</h3>
              <div class="d-flex flex-row align-items-center pe-4">
                <div class="p-2"><span class="fa fa-arrow-right"></span></div>
                <div class="p-2">
                  <div class="fs-5 fw-medium text-muted">
                    Find broken pages, spelling mistakes, broken links, layout and SEO issues before your users do.
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row align-items-center pe-4">
                <div class="p-2"><span class="fa fa-arrow-right"></span></div>
                <div class="p-2">
                  <div class="fs-5 fw-medium text-muted">
                    Make sure your site looks correct on all devices.
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row align-items-center pe-4">
                <div class="p-2"><span class="fa fa-arrow-right"></span></div>
                <div class="p-2">
                  <div class="fs-5 fw-medium text-muted">
                    Identify issues that are costing you conversions.
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <router-link class="btn btn-primary" aria-current="page" :to="{name: 'auth-signup'}">Get Started
                </router-link>
              </div>
            </div>
            <div class="col-5 feature-preview d-none d-lg-block">
              <div style="max-width: 320px" class="ps-2">
                <img class="img-fluid" src="/assets/media/video/audit3.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="container-fluid mt-4">
      <div class="row justify-content-center pb-4">
        <div class="col-md-8">
          <div class="row pt-6">
            <div class="col col-12 feature-preview d-block d-lg-none">
              <center>
              <div style="max-width: 540px">
                <img src="/assets/media/video/vscomp.png" class="img-fluid">
              </div>
              </center>
            </div>
            <div class="col-6 feature-preview d-none d-lg-block">
              <div style="max-width: 540px">
                <img src="/assets/media/video/vscomp.png" class="img-fluid">
              </div>
            </div>
            <div class="col-12 col-lg-6 ps-4">

              <h3 class="card-title mt-4 mb-3">Surpass your competitors</h3>
              <div class="d-flex flex-row align-items-center pe-4">
                <div class="p-2"><span class="fa fa-arrow-right"></span></div>
                <div class="p-2">
                  <div class="fs-5 fw-medium text-muted">
                    Find questions your competitors are answering that you are not.
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row align-items-center pe-4">
                <div class="p-2"><span class="fa fa-arrow-right"></span></div>
                <div class="p-2">
                  <div class="fs-5 fw-medium text-muted">
                    See where your competitors are outperforming you and how to fix it.
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row align-items-center pe-4">
                <div class="p-2"><span class="fa fa-arrow-right"></span></div>
                <div class="p-2">
                  <div class="fs-5 fw-medium text-muted">
                    Identify your unique advantages over your competitors.
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <a href="https://pagewatch.ai/daeeb804-75f5-46f6-b55e-0755cf51f95c/2ydb8goq/competitors/2lgm85u6" class="btn btn-warning" aria-current="page">View Demo
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-6">
      <div class="row justify-content-center section-color pb-4">
        <div class="col-md-8">
          <div class="row pt-6">
            <div class="col col-12 feature-preview d-block d-lg-none">
              <center>
                <div style="max-width: 540px">
                  <img src="/assets/media/video/insights.png" class="img-fluid">
                </div>
              </center>
            </div>
            <div class="col-12 col-lg-7">
              <h3 class="card-title mt-5 mb-3">AI Powered Feedback</h3>
              <div class="d-flex flex-row align-items-center pe-4">
                <div class="p-2"><span class="fa fa-arrow-right"></span></div>
                <div class="p-2">
                  <div class="fs-5 fw-medium text-muted">
                    Get detailed feedback on every section of your page to see what is working and what is not.
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row align-items-center pe-4">
                <div class="p-2"><span class="fa fa-arrow-right"></span></div>
                <div class="p-2">
                  <div class="fs-5 fw-medium text-muted">
                    Find parts of your pages are confusing to users.
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row align-items-center pe-4">
                <div class="p-2"><span class="fa fa-arrow-right"></span></div>
                <div class="p-2">
                  <div class="fs-5 fw-medium text-muted">
                   Powered by GPT-4 Vision.
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <router-link class="btn btn-primary" aria-current="page" :to="{name: 'auth-signup'}">Get Started
                </router-link>

              </div>
            </div>
            <div class="col-5 feature-preview d-none d-lg-block">
              <div style="max-width: 340px">
                <img src="/assets/media/video/insights.png" class="img-fluid">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div id="one-vue-trusted-by" class="bg-body-extra-light">
      <div class="content py-6">
        <div class="py-5 text-center">
          <h2 class="h1 fw-black mb-4" id="pricing">
            Pricing
          </h2>
          <div class="text-muted mb-2">Prepaid, pay only for what you use.</div>
          <div class="row justify-content-center text-center">
            <div class="col-lg-4 col-xl-3 col-md-12 mb-4">
              <div class="card card2 h-100" style="border:1px solid rgba(128,128,128,0.51)">
                <div class="card-body ">

                  <span class="h2">$2.00</span> /page<br>
                  <small class="text-muted">No Monthly Fees</small>
                  <div class="mt-2 p-2">
                    <router-link class="btn btn-primary" aria-current="page" :to="{name: 'auth-signup'}">Get Started
                    </router-link>
                  </div>

                </div>


              </div>

            </div>


          </div>
        </div>

      </div>
    </div>
    <!-- END Trusted By -->


    <!-- Footer -->
    <footer id="page-footer" class="">
      <div class="content py-5">
        <div class="row fs-sm fw-medium justify-content-center">
          <div class="col-sm-6 order-sm-1 py-1">
            <a class="fw-semibold" href="https://pagewatch.ai">{{
                store.app.name
              }}</a>
            &copy; {{ store.app.copyright }}

            <router-link :to="{name: 'privacy-policy'}" class="ms-4">Privacy Policy</router-link>
            <a class="ms-4" href="mailto:hi@pagewatch.ai">Email</a>
            <a class="ms-4" target="_blank" href="https://x.com/pagewatchdev">Twitter</a>
          </div>

        </div>
      </div>
    </footer>
    <!-- END Footer -->
  </div>
</template>
<style>
.bg-page-landingx {
  background-image: linear-gradient(to bottom, transparent 0%, rgba(188, 134, 90, 0.12) 50%, transparent 100%),
  linear-gradient(to right, transparent 0%, rgba(188, 134, 90, 0.12) 50%, transparent 100%),
  radial-gradient(circle, rgba(7, 7, 7, 0.09) 1px, transparent 0);
  background-size: cover, 20px 20px;
}

.bg-page-landing {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(220, 164, 116, 0.07) 20%, rgba(216, 161, 118, 0.07) 90%, rgba(255, 255, 255, 1) 100%),
  radial-gradient(circle, rgba(7, 7, 7, 0.09) 1px, transparent 0);
  background-size: auto, 20px 20px;
}

.vjs-control-bar {
  display: none !important;
}

.social-proof-body {
  background-color: rgba(255, 163, 40, 0.09);
  border-radius: 10px;
  font-size: 1.0em;
}

.social-proof-author {
  font-size: 0.8em;
}

.social-proof-source {
  font-size: 0.8em;
  color: #6c757d;
}

.section-color {
  background-color: #EAEEF2
}

.feature-preview .vjs-poster img, .feature-preview .vjs-tech {
  border-radius: 20px;
  background-color: rgba(255, 163, 40, 0.09);
}

.feature-preview .video-js {
  border-radius: 20px;
  background-color: rgba(255, 163, 40, 0.09);
}

.social-proof-box {
  max-width: 680px;
  border: 1px solid rgba(128, 128, 128, 0.26);
  border-radius: 8px;
  color: #676768;
  font-size: 11pt;
}

/* hide videojs errors */
.vjs-error-display {
  display: none !important;
}

.vjs-poster {
  background-color: white !important;
}
</style>
